<template>
  <div class="findings-image-wrapper">
    <div ref="findings-overlay"
         class="findings-overlay"
         @click="prepareFinding($event)"
         @dragleave="(ev) => ev.preventDefault()"
         @dragover="(ev) => ev.preventDefault()"
    >
      <div v-for="(finding, index) in findings.filter(f => !!f && f.nodeSize === size).concat([newFinding])"
           :id="finding.id"
           class="findings-spot"
           @click="$event.stopPropagation()"
           :style="'top:'+finding.location.y+'%;left:'+finding.location.x+'%;'"
           @mouseenter="$emit('findingEnter', finding.id)"
           @mouseleave="$emit('findingLeave', finding.id)"
           draggable="true"
           @dragend="moveFinding(finding, $event)"
           :key="index"
      >
        <!--<div v-if="finding.type === 'area'" class="spot" :class="finding.type" :style="'width:'+finding.location.x2+'%;'"></div>-->
        {{ index+1 }}
        <div @click="newFinding = finding; findingPrepared = true" class="finding-preview-tooltip">
          <div class="card">
            <div class="card-body p-1">
              {{ finding.title }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="findingPrepared" class="findings-card card"
           :style="'top:'+newFinding.location.y+'%;left:'+newFinding.location.x+'%;'"
      >
        <div class="card-header p-1">
          <strong>Neues Finding:</strong>
          <i @click="cancelPreparation($event)" class="fas fa-times pt-1 float-right text-danger"></i>
        </div>
        <div class="card-body p-1">
          <textarea v-model="newFinding.title" @keyup.enter="addFinding($event)" class="form-control form-control-sm m-0"></textarea>
        </div>
        <div class="card-footer p-1">
          <button @click="addFinding($event)" class="btn btn-sm btn-block btn-primary">Speichern</button>
        </div>
      </div>
    </div>
    <div v-for="(imageSize, index) in Object.keys(node.image)" :key="index">
      <img v-show="imageSize === size" :src="getImg(node, imageSize)" alt="" :id="'findings-image-' + node.id + '-' + imageSize"
           class="findings-image img-fluid"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FindingsImage',
  components: {},
  directives: {},
  props: {
    node: Object,
    size: String,
    findings: Array,
    adding: Boolean,
    theoryId: String
  },
  data() {
    return {
      findingPrepared: false,
      newFinding: {
        id: "",
        theoryId: this.theoryId || this.activeTheoryId,
        stepId: "",
        auditId: "",
        nodeUrl: "",
        nodeSize: "",
        timestamp: +Date.now(),
        title: "",
        levels: [],
        patterns: [],
        tags: [],
        type: "point",
        location: {
          x: '-10',
          y: '-10'
        },
      },
      image: null
    }
  },
  computed: {},
  watch: {},
  methods: {
    prepareFinding($event) {
      $event.stopPropagation();
      $event.preventDefault();
      if (!this.adding || this.findingPrepared) return;

      this.newFinding.id = this.generateId();
      this.newFinding.nodeUrl = this.node.url;
      this.newFinding.nodeSize = this.size;

      let x = $event.offsetX / this.image[this.size].offsetWidth * 100;
      let y = $event.offsetY / this.image[this.size].offsetHeight * 100;
      if (0 < x && x < 100 && 0 < y && y < 100) {
        this.newFinding.location = { x, y };
        this.findingPrepared = true;
        this.$refs['findings-overlay'].addEventListener("click", this.findingClickaway);
      }
      return false;
    },
    cancelPreparation($event) {
      $event.stopPropagation();
      this.findingPrepared = false;
      this.newFinding.location = {
        x: '-10',
        y: '-10'
      };
      this.$refs['findings-overlay'].removeEventListener("click", this.findingClickaway);
    },
    findingClickaway(event) {
      let parent = event.target.parentElement.classList.contains("findings-card");
      let parentParent = event.target.parentElement.parentElement.classList.contains("findings-card");
      if (!event.target.classList.contains("findings-card") && !parent && !parentParent) {
        this.cancelPreparation(event);
        this.$refs['findings-overlay'].removeEventListener("click", this.findingClickaway);
      }
    },
    addFinding($event) {
      $event.stopPropagation();
      if (this.findings.find(f => f.id === this.newFinding.id)) {
        this.$emit('editFinding', this.newFinding);
      } else {
        this.$emit('addFinding', this.newFinding);
      }
      this.findingPrepared = false;
      this.$refs['findings-overlay'].removeEventListener("click", this.findingClickaway);
      this.newFinding = {
        id: "",
        theoryId: this.activeTheoryId,
        auditId: "",
        stepId: "",
        nodeUrl: "",
        timestamp: +Date.now(),
        title: "",
        levels: [],
        patterns: [],
        labels: [],
        type: "point",
        location: {
          x: '-10',
          y: '-10'
        },
      };
    },
    moveFinding(finding, $event) {
      $event.preventDefault();
      $event.stopPropagation();

      let x = finding.location.x + $event.layerX / this.image[this.size].offsetWidth * 100;
      let y = finding.location.y + $event.layerY / this.image[this.size].offsetHeight * 100;
      if (0 < x && x < 100 && 0 < y && y < 100) {
        finding.location = { x, y };
        this.$emit('editFinding', finding);
      }
      this.$refs['findings-overlay'].removeEventListener("click", this.findingClickaway);
      return false;
    }
  },
  mounted() {
    this.image = {
      'desktop': document.getElementById('findings-image-' + this.node.id + '-desktop'),
      'tablet': document.getElementById('findings-image-' + this.node.id + '-tablet'),
      'mobile': document.getElementById('findings-image-' + this.node.id + '-mobile'),
    }
  }
}
</script>

<style scoped lang="scss">
.findings-image-wrapper {
  position: relative;

  .findings-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    .findings-card, .findings-spot {
      position: absolute;
      z-index: 2;
      box-shadow: 0 1rem 3rem 0 rgba(0,0,0,.3);
    }

    .findings-spot {
      width: 2rem;
      height: 2rem;
      border-radius: 100%;
      text-align: center;
      background: var(--primary-dark);
      color: var(--white);
      border: 3px solid rgba(255,255,255,.3);
      transform: translate(-50%,-50%);
      opacity: .8;

      .finding-preview-tooltip {
        display: none;
        width: 200px;
        color: var(--dark);
      }
      &:hover {
        .finding-preview-tooltip {
          display: block;
        }
      }
    }
  }

  .findings-image {
    width: 100%;
    &.placeholder {
      width: 100%;
      height: 15rem;
      background: var(--light);
    }
  }
}
</style>