<template>
  <div id="findings-list">
    <ol class="list-unstyled">
      <li v-for="(finding, index) in findings"
          class="card mb-2"
          :class="finding.id === highlightedFindingId ? 'bg-primary text-white' : ''"
          :key="index"
          :id="'finding-' + finding.id"
      >
        <div class="card-body" :class="reduced ? 'p-2' : ''">
          <a v-if="size.prop === finding.size" :href="'#' + finding.id" class="h6 mb-0">
            {{ finding.title }} ({{ finding.nodeSize }})
          </a>
          <p v-else class="h6 mb-0 text-muted">
            {{ finding.title }} ({{ finding.nodeSize }})
          </p>
          <i @click="remove(finding.id)" class="fal fa-minus-circle text-danger"></i>
        </div>
        <!--<div @click="toggleFindingDetails(finding)" class="card-header py-1 text-center" :class="reduced ? 'p-1' : ''">
          <i class="fad fa-caret-down"></i>
        </div>-->
        <div class="card-body details" style="display:none" :class="reduced ? 'p-2' : ''">
          <label>Gehört die Idee zu der aktuellen Theorie?</label>
          <select @change="setTheory(finding, $event)" class="form-control mb-3">
            <option disabled>Theorie wählen</option>
            <option value="">Keine Theorie</option>
            <option v-for="(theory, index) in project.theories"
                    :value="theory.id"
                    :selected="finding.theoryId === theory.id"
                    :key="index"
            >
              {{ theory.title }}
            </option>
          </select>
          <div v-for="(prop, index) in props" class="form-group" :key="index">
            <label class="form-group-label">{{ prop.title }}:</label>
            <ul class="list-group">
              <li v-for="(option, index) in finding[prop.prop]" class="list-group-item" :key="index">
                {{ option }}
                <i @click="removeProp(finding, prop.prop, index)" class="fal fa-minus-circle text-danger float-right"></i>
              </li>
              <li v-if="prop.type === 'text'" class="">
                <input type="text" @change="addProp(finding, prop.prop, $event)" class="form-control form-control-sm"
                       placeholder="Neuen Punkt hinzufügen..."/>
              </li>
              <li v-else>
                <select @change="addProp(finding, prop.prop, $event)" class="form-control">
                  <option value="" selected>{{ prop.title }} auswählen</option>
                  <option v-for="(option, index) in prop.options" :value="option" :key="index">{{ option }}</option>
                </select>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ol>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'FindingsList',
  components: {},
  props: {
    findings: Array,
    highlightedFindingId: String,
    reduced: Boolean,
    size: String
  },
  data() {
    return {
      props: [
        {
          prop: "tags",
          title: "Tags",
          type: "text"
        },
        /*
        {
          prop: "patterns",
          title: "Behaviour Patterns",
          type: "select",
          options: [
            "Affect Heuristic",
            "Anchoring",
            "Authenticity",
            "Authority",
            "Autonomy",
            "Availability Heuristics",
            "Banner Blindness",
            "Bonding",
            "Breaking Rules",
            "Caring",
            "Cashless Effect",
            "Challenge",
            "Cheering",
            "Completion",
            "Compromise Effect",
            "Consistency/Commitment",
            "Context",
            "Contrast Effect",
            "Curiosity",
            "Decoy Effect",
            "Default",
            "Diderot Effect",
            "Emotional Resonance",
            "Endowment Effect",
            "Faces and Views",
            "Feedback",
            "Flow",
            "Framing",
            "Free",
            "Halo Effect",
            "Hope",
            "Hyperbolic discounting",
            "Idleness Aversion",
            "IKEA-Effect",
            "Illusion of Control",
            "Illusion of Truth",
            "Implicit Signals",
            "Inner Dialogue",
            "Joy and Fun",
            "Known",
            "Kognitive Dissonanz",
            "Kognitive Leichtigkeit",
            "Loss Aversion",
            "Mental Models",
            "Mere Exposure Effect",
            "Mirroring",
            "Neomanie",
            "Nonsense Math Effect",
            "Nostalgia Effect",
            "Nudging",
            "Order and Harmony",
            "Paradox of Choice",
            "Patterns and Principles",
            "Peak End Rule",
            "Preview",
            "Price Perception",
            "Primacy-Recency Effect",
            "Ranking",
            "Reactance & Censoring",
            "Reason Why",
            "Reciprocity",
            "Reward",
            "Rhyme-as-Reason Effect",
            "Rules",
            "Scarcity",
            "Self Expression",
            "Self fulfilling prophecy",
            "Sensation",
            "Social Proof",
            "Status Quo Bias",
            "Story-Telling",
            "Success",
            "Swimmer's Body Illusion",
            "Sympathy",
            "Tension & Closure",
            "Test Pattern",
            "Testimonials",
            "Trust",
            "Try Out",
            "Unlocking",
            "Value",
            "Well Travelled Road Effect",
            "Zero-Risk Bias",
          ]
        },
        {
          prop: "levels",
          title: "7-Ebenen",
          type: "select",
          options: [
            "Relevanz",
            "Vertrauen",
            "Orientierung",
            "Stimulanz",
            "Komfort",
            "Sicherheit",
            "Bewertung"
          ]
        },
        {
          prop: "limbics",
          title: "Charaktertyp",
          type: "select",
          options: [
            "Abenteuerlustig",
            "Hedonistisch",
            "Offen",
            "Harmonisch",
            "Traditionell",
            "Diszipliniert",
            "Ehrgeizig"
          ]
        },
        */
      ],
    }
  },
  computed: {
  },
  methods: {
    toggleFindingDetails(finding) {
      let el = document.querySelector('#finding-' + finding.id + ' .details');
      el.style.display = el.style.display === 'none' ? 'block' : 'none';
    },
    remove(id) {
      let findings = JSON.parse(JSON.stringify(this.project.findings));
      let index = findings.findIndex(f => f.id === id);
      if (confirm('Remove "' + findings[index].title + '" from Findings' +
          (findings[index].auditId ? ' and from Audit' : '') + '?')) {
        findings.splice(index, 1);
        this.$store.dispatch('project/updateProjectByProp', {prop: 'findings', data: findings})
            .then(() => {
              this.$emit('removeFinding', id);
            });
      }
    },
    removeProp(finding, prop, index) {
      let findings = JSON.parse(JSON.stringify(this.project.findings));
      findings.find(f => f.id === finding.id)[prop].splice(index, 1);
      this.$store.dispatch('project/updateProjectByProp', {prop: 'findings', data: findings})
    },
    addProp(finding, prop, $event) {
      let newVal = $event.target.value;
      if (newVal === "") return;

      let findings = JSON.parse(JSON.stringify(this.project.findings));
      findings.find(f => f.id === finding.id).push(newVal);

      $event.target.value = "";
      this.$store.dispatch('project/updateProjectByProp', {prop: 'findings', data: findings})
    },
    setTheory(finding, $event) {
      let findings = JSON.parse(JSON.stringify(this.project.findings));
      findings.find(f => f.id === finding.id).theoryId = $event.target.value;
      this.$store.dispatch('project/updateProjectByProp', {prop: 'findings', data: findings})
        .then(res => {
          console.debug(res);
        });
    },
  },
  beforeMount() {
  }
}
</script>

<style lang="scss" scoped>
li {
  position: relative;
  width: calc(100% - 1.5rem);
  list-style: none;

  i.fa-minus-circle {
    position: absolute;
    right: -1.5rem;
    top: .3rem;
    cursor: pointer;
  }

  &::after {
    content: "";
    display: table;
    clear: bottom;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}
</style>
