<template>
    <div class="alert alert-primary d-inline-block ml-3 mb-5">
      <div class="alert-body">
        <div class="row align-items-center">
          <div class="col-auto pr-0 pl-4">
            <i class="fad fa-info-circle"></i>
          </div>
          <div class="col pr-4">
            <p class="mb-0">Theorie wechseln um weitere {{ item }} zu sehen</p>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'TheorySwitchHint',
  components: {},
  directives: {},
  props: {
    item: String
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.alert {
  padding: .4rem .5rem;
  vertical-align: middle;

  i {
    color: var(--primary-dark) !important;
  }
}
</style>